
.projects--section {
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 5%;
}

.carousel-component {
    margin-top: 5%;
    padding-top: 5%;
    display: flex;
    justify-content: center;
}

.carousel-item-component {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1300px) {
}


@media (max-width: 800px) {

}

@media (max-width: 500px) {
}