.skills--art-bg-component {
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5vw;
    width: 45%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    border-style: solid;
    border-color: #831187;
    backdrop-filter: blur(4px);
    border-width: 2px;
    padding: 4% 3% 3% 4%;
}

.skills--icon-component {
    width: 120px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -60px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.skills--title-component {
    margin-top: 35px;
    font-weight: 400;
    margin-bottom: 5%;
}


br {
    display: block;
    content: ""; 
    margin-top: -10px;
}

.skills--p-component{
    margin-top: 2%;
    text-align: left;
    width: 100%;
}

.skills--p-columns {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    justify-content: space-around;
}

.right-brain {
    position: absolute;
    left: 10px;
    z-index: -4;
    height: 80%;
    top: 50%;
    transform: translate(0%, -50%);
}


@media (max-width: 1300px) {
    
    .skills--icon-component {
        width: 100px;
        top: -50px;
    }

    .skills--p-columns {
        font-size: 0.9rem;
    }

    .skills--p-component{
        font-size: 1rem;
    }

    .skills--title-component {
        font-size: 1.4rem;
    }
    

}


@media (max-width: 1000px) {
    
    .skills--icon-component {
        width: 80px;
        top: -40px;
    }
    .skills--art-bg-component {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15%;
    }
    

}

@media (max-width: 750px) {

    .skills--icon-component {
        width: 70px;
        top: -35px;
    }
    .skills--art-bg-component {
        font-size: 2vw;
    }
    .skills--art-info-component {
        padding: 7% 7% 7% 7%;
    }

    .skills--title-component {
        font-size: 1.2rem;
    }

    .skills--p-component{
        font-size: 0.9rem;
    }

    .skills--p-columns {
        font-size: 0.7rem;
    }

}

@media (max-width: 500px) {

    .skills--icon-component {
        width: 60px;
        top: -30px;
    }

}