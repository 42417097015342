.react-canvas {
    height: 200px;
    margin-left: -10%;
    margin-right: -10%;
    
}

@media (max-width: 1050px) {
    
    .react-canvas {
        height: 175px;
        margin-left: -10%;
        margin-right: -10%;
    }

}

@media (max-width: 800px) {
    
    .react-canvas {
        height: 150px;
        right: 5%;
    }

}

@media (max-width: 650px) {
    
    .react-canvas {
        height: 125px;
        right: 0;
        top: -5%;
    }

}

@media (max-width: 500px) {
    
    .react-canvas {
        height: 100px;
        right: -10%;
    }

}

@media (max-width: 400px) {
    
    .react-canvas {
        height: 100px;
        right: -20%;
    }

}