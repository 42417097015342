.proj--component {
    height: 100%;
    width: 45%;
    padding: 3%;
    z-index: 4;
    margin-bottom: 7%;
    color: white;
    font-size: 1vw;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    border-style: solid;
    border-color: #831187;
    backdrop-filter: blur(4px);
    border-width: 2px;
    position: relative;
}

.proj--logo-container {
    width: 70%;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    background: rgb(240, 240, 240);
    height: 80px;
    top: -40px;
    border-radius: 50px;
}

.silas-logo  {
    transform: scale(0.58);
}

.proj--buttons-container {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
}

.proj--buttons {
    width: 50px;
    margin-left: 5%;
    margin-right: 5%;
    cursor: pointer;
}

.proj--buttons:active {
    transform: scale(0.9);
}

.proj--content {
    margin-top: 2%;
    text-align: left;
    max-height: 200px;
    overflow: auto;
    padding: 5%;
    font-size: 1rem;
}

.proj--js-content {
    margin-top: 2%;
    text-align: left;
    overflow: auto;
    max-height: 150px;
    padding: 5%;
    font-size: 1rem;
}

.proj--two-images {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
    height: 200px;
}

.proj--image {
    width: 200px;
}

@media (max-width: 1300px) {
   
    .proj--content {
        margin-top: 7%;
        height: 150px;
        overflow: auto;
    }

    .proj--two-images {
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .proj--buttons {
        width: 50px;
        margin-left: 5%;
        margin-right: 5%;
        cursor: pointer;
    }

    .proj--image {
        width: 120px;
    }

}@media (max-width: 1100px) {
   
    .proj--content {
        margin-top: 7%;
        height: 120px;
        overflow: auto;
    }

    .proj--buttons {
        width: 50px;
        margin-left: 5%;
        margin-right: 5%;
        cursor: pointer;
    }

    .proj--image {
        width: 100px;
    }

    .proj--logo-container {
        width: 70%;
        top: -30px;
        height: 60px;
    }
    
    .silas-logo {
        transform: scale(0.5);
    }

}


@media (max-width: 800px) {
    .proj--component {
    width: 85%;
    font-size: 2.5vw;
    margin-top: 5%;
    font-size: 0.8rem;
    }

    .proj--content {
        margin-top: 7%;
        height: 200px;
        overflow: auto;
    }

    .proj--buttons {
        width: 50px;
        margin-left: 5%;
        margin-right: 5%;
        cursor: pointer;
    }

    .proj--image {
        width: 150px;
    }

    .proj--logo-container {
        width: 40%;
        height: 100px;
        top: -50px;
    }

    
    .proj--two-images {
        justify-content: space-evenly;
    }
    
    .silas-logo {
        transform: scale(0.8);
    }

}

@media (max-width: 650px) {
        .proj--content {
            height: 150px;
        }
    
    
        .proj--image {
            width: 150px;
        }
    

}
@media (max-width: 600px) {
    
    
        .proj--buttons {
            width: 40px;
        }
    
        .proj--image {
            width: 100px;
        }
        
        .silas-logo {
            transform: scale(0.65);
        }

}

@media (max-width: 500px) {
    

    .proj--buttons {
        width: 40px;
    }

    .proj--image {
        width: 100px;
    }
    
    .silas-logo {
        transform: scale(0.55);
    }

}