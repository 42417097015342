.navbar-mobile-container {
    position: fixed;
    z-index: 10;
    top: 5%;
    right: 5%;
}

.close-menu {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu:active, .close-menu:active {
    transform: scale(0.9);
}

@media (max-width: 1300px) {

    .close-menu {
        width: 80px;
        height: 80px;
    }
    
    .hamburger-menu {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 800px) {

    .close-menu {
        width: 60px;
        height: 60px;
    }
    
    .hamburger-menu {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 500px) {

    .close-menu {
        width: 55px;
        height: 55px;
    }
    
    .hamburger-menu {
        width: 55px;
        height: 55px;
    }
}