.adobe-canvas {
    height: 200px;
    margin-left: -10%;
    margin-right: -10%;
}

@media (max-width: 1050px) {
    
    .adobe-canvas {
        height: 175px;
        margin-left: -10%;
        margin-right: -10%;
    }

}

@media (max-width: 800px) {
    
    .adobe-canvas {
        height: 150px;
    }

}

@media (max-width: 650px) {
    
    .adobe-canvas {
        height: 125px;
    }

}

@media (max-width: 500px) {
    
    .adobe-canvas {
        height: 100px;
    }

}

@media (max-width: 400px) {
    
    .adobe-canvas {
        height: 100px;
    }

}