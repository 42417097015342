.navlinks-container {
    position: absolute; 
    z-index: 8;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}

.navigation--separation-bar {
    height: 150px;
    width: 8px;
}

.navigation--separation-bar-second {
    height: 150px;
    width: 8px;
}

.navigation--icon {
    cursor: pointer;
    text-decoration: none;

}

.navigation--image {
    width: 100%;
}

.navigation--image:active {
    transform: scale(0.9);
}

.navigation-text {
    color: white;
    text-decoration: none;
    text-align: center;
}

.navigation--icon:active {
    transform: scale(0.9);
}

.navlinks--arrow-component {
    
    transform: scale(0.8);
}

@media (max-width: 550px) {

    .navlinks-link {
        font-size: 1.2rem
    }
}

@media (max-width: 400px) {

    .navlinks-link {
        font-size: 1.3rem

    }
}