.CTA-button {
    position: fixed;
    left: 50%;
    top: 90%;
    transform: translateX(-50%);
    z-index: 8;
    cursor: pointer;
    background-image: linear-gradient(90deg, rgba(239,154,242,1) 0%, rgba(161,20,185,1) 12%, rgba(138,4,237,1) 24%, rgba(87,2,150,1) 37%, rgba(36,12,118,1) 49%, rgba(87,2,150,1) 62%, rgba(138,4,237,1) 75%, rgba(161,20,185,1) 87%, rgba(239,154,242,1) 100%);
    background-size: 350%;
    text-align: center;
    color: white;
    text-decoration: none;
    display: block;
    border-radius: 50px;
    width: 160px;
    height: 42px;
    overflow: hidden;
    font-weight: 400;
}

.CTA-button {
    animation: play 3s infinite alternate;
}


.CTA-button p {
    font-size: 20px;
    display: block;
    margin: 4px;
    padding: 5px;
    background: black;
    border-radius: 50px;
}

@keyframes play {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@media (max-width: 1300px) {
        
    .CTA-button {
        background-size: 200%;
        width: 120px;
        height: 35px;
    }

    .CTA-button p {
        font-size: 15px;
        margin: 4px;
        padding: 4.5px;
    }

}

@media (max-width: 800px) {
        
    .CTA-button {
        left: 50%;
        transform: translateX(-50%);
        background-size: 200%;
        width: 120px;
        height: 35px;
    }

    .CTA-button p {
        font-size: 15px;
        margin: 4px;
        padding: 4.5px;
    }

}
