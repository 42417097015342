.form--container {
    width: 80%;
    
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    padding: 6% 6% 1% 6%;
    border-radius: 20px;
    border-style: solid;
    border-color: #831187;
    border-width: 1px;
}

.input {
    border-radius: 50px;
    font-size: 1.3em;
    background: rgba(29, 0, 43, 0.451);
    backdrop-filter: blur(3px);
    border-color: transparent;
    width: 100%;
    padding: 1% 5% 1% 5%;
    margin-bottom: 35px;
    color: #c0c0c0;
}



textarea {
    font-family: 'Lato', sans-serif;
}



.input-button {
    border-radius: 50px;
    backdrop-filter: blur(3px);
    width: 30%;
    font-size: 1.3em;
    cursor: pointer;
    background: rgba(29, 0, 43, 0.4);
    backdrop-filter: blur(3px);
    border-color: transparent;
    margin-bottom: 35px;
    padding-top: 1%;
    padding-bottom: 1%;
    color: #c0c0c0;
}

.input-button:active {
    transform: scale(0.9);
}

@media (max-width: 1100px) {
    .form--container {
        width: 100%;
    }
    .input-button {
        font-size: 1.2em;
    }
    

}