
.about--section {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 45px;
    border-style: solid;
    border-color: #831187;
    border-width: 2px;
    backdrop-filter: blur(4px);
    max-width: 1200px;
}

.about--texts {
    position: relative;
    color: white;
    font-family: var(--main-font);
    text-align: left;
    font-size: 1.3rem;
    margin-top: 4%;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 2%;
    z-index: 3;
}

.about--texts p {
    position: relative;
    margin-bottom: 2%;
    z-index: 2;
}


.triangles {
    width: 20%;
}

.first-triangles {
    position: absolute;
    animation: rightRotation 30s infinite linear;
    overflow: hidden;
    right: 0;
    top: 0;
}

.second-triangles {
    position: absolute;
    left: 0;
    bottom: 0;
    animation: leftRotation 26s infinite linear;
    overflow: hidden;
}

.about--medal-container{
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 150px 150px;
    grid-template-columns: 33% 33% 33%;
}

@keyframes rightRotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@keyframes leftRotation {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.about--quote {
    color: white;
    font-family: var(--main-font);
    font-size: 3vw;
    font-style: italic;
    margin-top: 50px;
    margin-left: 5%;
    margin-right: 5%;
    position: relative;
    z-index: 3;
}

.quote-main {
    font-weight: bold;
}

.asset-container {
    position: absolute;
    z-index: 0;
    height: 800px;
    width: 450px;
    overflow: hidden;
    right: 0;
    top: 75%;
}

.asset1 {
    height: 100%;
    margin-left: 90px;
    animation: rightRotation 30s infinite linear;
}


@media (max-width: 1800px) {
    
    .first-triangles {
        overflow: hidden;
    }
}


@media (max-width: 1300px) {
    .triangles {
        width: 15%;
    }

    .asset-container {
        height: 600px;
        width: 350px;
    }

    .about--texts {
        margin-left: 7%;
        margin-right: 7%;
    }

}


@media (max-width: 1000px) {

    .about--section {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}


@media (max-width: 800px) {

    .asset-container {
        height: 400px;
        width: 250px;
    }
    .about--texts {
        font-size: 1rem;
    }

}

@media (max-width: 600px) {

    .asset-container {
        height: 300px;
        width: 175px;
    }
    
    .asset1 {
        margin-left: 50px;
    }
    .about--texts {
        font-size: 0.9rem;
    }

    .about--medal-container{
        margin-bottom: -15%;
    }

}

@media (max-width: 450px) {

    .about--medal-container{
        margin-bottom: -25%;
    }

}
