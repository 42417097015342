@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

.main-title {
  font-family: 'Gruppo', cursive;
  font-size: 5.5Rem;
  color: #dedede;
  font-weight: 400;
}


@media (max-width: 1300px) {
    
  .main-title {
  font-size: 4rem;
  }
  
}


@media (max-width: 1000px) {
    
  .main-title {
  font-size: 3.5rem;
  }

}


@media (max-width: 700px) {
    
  .main-title {
  font-size: 3rem;
  }

}


@media (max-width: 500px) {
    
  .main-title {
  font-size: 2.5rem;
  }

}


@media (max-width: 400px) {
    
  .main-title {
  font-size: 2rem;
  }

}