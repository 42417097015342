
.footer-section {
    z-index: 7;
    width: 100%;
    background-color: #0C0129;
    justify-content: left;
}

.footer-section p {
    color: white;
    margin-left: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 1vw;
}

@media (max-width: 1300px) {
    
    .footer-section p {
        font-size: 1.5vw;
    }
    

}

@media (max-width: 1100px) {
    
    .footer-section p {
        font-size: 1.8vw;
    }
    

}

@media (max-width: 800px) {
    
    .footer-section p {
        font-size: 2.2vw;
    }

}

@media (max-width: 600px) {
    
    .footer-section p {
        font-size: 3vw;
    }

}
