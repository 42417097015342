.header-section {
    color: #d6d6d6;
    height: 95vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

}

.header-second-title {
    font-family: 'Vujahday Script', cursive;
    font-size: 5.5vw;
}

.header-logo-second {
    
}

.medal-blocker {
    height: 500px;
    width: 500px;
    position: absolute;
    z-index: 8;
    left: 50%;
    transform: translateX(-50%);
}



@media (max-width: 1300px) {
    

.header-second-title {
    font-size: 6.5vw;
}
}

@media (max-width: 1100px) {

    .header-logo img {
        width: 300px;
    }  

    .medal-blocker {
        height: 300px;
    }

}

@media (max-width: 800px) {

    .header-logo img {
        width: 250px;
    }
    

    .header-second-title {
        font-size: 7.5vw;
    }
}

@media (max-width: 700px) {

    .header-logo img {
        width: 200px;
    }

    .medal-blocker {
        height: 300px;
        width: 300px;
        position: absolute;
        z-index: 8;
        left: 50%;
        transform: translateX(-50%);
    }

}



