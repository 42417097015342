
.skills-section {
    margin: 7% 5% 0% 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.skills--info-container {
    display: flex;
    justify-content: space-around;
    position: relative;
    z-index: 3;
    margin-top: 7%;
}


@media (max-width: 1000px) {

    .skills--info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        z-index: 3;
        margin-bottom: 7%;
    }
    

}


@media (max-width: 800px) {

    .skills-title {
        width: 35%;
    }

    .skills--info-container {
        margin-top: 5%;
    }
    

}