.proj--game-title {
    color: black;
    font-size: 1.5vw;
    margin-top: auto;
    margin-bottom: auto;
}

.game-logo {
    transform: scale(0.8);
}

.proj--game-title {
    font-size: 1.3vw;
}
