* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.background {
  position: absolute;
  z-index: -9;
  height: 100%;
}