.node-canvas {
    height: 200px;
    margin-left: -10%;
    margin-right: -10%;
    
}

@media (max-width: 1050px) {
    
    .node-canvas {
        height: 175px;
        margin-left: -10%;
        margin-right: -10%;
    }

}

@media (max-width: 800px) {
    
    .node-canvas {
        height: 125px;
        left: -10%;
    }

}

@media (max-width: 650px) {
    
    .node-canvas {
        height: 125px;
        left: -15%;
    }

}

@media (max-width: 500px) {
    
    .node-canvas {
        height: 100px;
        left: -25%;
    }

}

@media (max-width: 400px) {
    
    .node-canvas {
        height: 100px;
        left: -35%;
    }

}