
.proj--one-images-component {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
    height: 200px;
}

.proj---one-image-component {
    width: 350px;
}

.affordable-logo {
    transform: scale(0.75);
}

@media (max-width: 1300px) {
   
    .proj--content {
        margin-top: 7%;
        height: 150px;
        overflow: auto;
    }

    .proj--two-images-embassy {
        margin-top: 3%;
        margin-bottom: 3%;
        flex-direction: column;
    }

    .proj--buttons {
        width: 50px;
        margin-left: 5%;
        margin-right: 5%;
        cursor: pointer;
    }

    .proj---embassy-image {
        width: 160px;
    }

    .affordable-logo {
        transform: scale(0.65);
    }

}@media (max-width: 1100px) {
   
    .proj--content {
        margin-top: 7%;
        height: 120px;
        overflow: auto;
    }

    .proj--buttons {
        width: 50px;
        margin-left: 5%;
        margin-right: 5%;
        cursor: pointer;
    }

    .proj---embassy-image {
        width: 130px;
    }

    .proj--logo-container {
        width: 70%;
        top: -25px;
        height: 50px;
    }

    .proj---one-image-component {
        width: 280px;
    }

}

@media (max-width: 900px) {
    

.proj---one-image-component {
    width: 240px;
}
}

@media (max-width: 800px) {

    .proj---one-image-component {
        width: 350px;
    }

    .proj--component {
    font-size: 2.2vw;
    margin-top: 5%;
    }

    .proj--content {
        margin-top: 7%;
        height: 200px;
        overflow: auto;
    }

    .proj--buttons {
        width: 50px;
        margin-left: 5%;
        margin-right: 5%;
        cursor: pointer;
    }

    .proj---embassy-image {
        width: 100px;
    }

    
    .proj--two-images-embassy {
        justify-content: space-evenly;
    }

}

@media (max-width: 650px) {
        .proj--content {
            height: 150px;
        }
    
    
        .proj--image {
            width: 150px;
        }

.proj---one-image-component {
    width: 300px;
}


}
@media (max-width: 600px) {
    
        .proj--buttons {
            width: 40px;
        }
    
        .proj--image {
            width: 100px;
        }
        

}

@media (max-width: 500px) {

    .proj--buttons {
        width: 40px;
    }

    .proj--image {
        width: 100px;
    }

    .embassy-logo {
        transform: scale(0.8);
    }

    .proj---one-image-component {
        width: 260px;
    }
    

}