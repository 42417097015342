.skills--bg-component{
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5vw;
    width: 45%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    border-style: solid;
    border-color: #831187;
    border-width: 2px;
    backdrop-filter: blur(4px);
    padding: 4% 3% 3% 4%;
}

.left-brain {
    position: absolute;
    right: 10px;
    z-index: -4;
    height: 80%;
    top: 50%;
    transform: translate(0%, -50%);
}


@media (max-width: 1000px) {

    .skills--bg-component {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15%;
    
    }

}



@media (max-width: 750px) {

    .skills--bg-component {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15%;
    
    }

    .skills--info-component {
        padding: 7% 7% 7% 7%;
    }

}
